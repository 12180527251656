/* Helpers ===================================== */

.col-white {
  color: #FFF !important;
}

.img-fullwidth {
  display: inline-block;
  width: 100% !important;
  height: auto !important;
}
.img-fullheight {
  display: inline-block;
  height: 100% !important;
  width: auto !important;
}

.no-padding {padding: 0 !important;}
.no-margin {margin: 0 !important;}
.middle {vertical-align: middle !important;}
.cursor {cursor: pointer !important;}
.absolute {position: absolute !important;}
.relative {position: relative !important;}
.fixed {position: fixed !important;}
.hidden {display: none !important;}
.hidden-n {display: none;}
.no-opacity {opacity: 0;}
.no-wrap {white-space: nowrap !important;}
.wrap {white-space: normal !important;}
.breakword {word-break: break-all !important;}
.flex {display: flex !important;}
.flex-vertical {display: flex !important;flex-direction: column !important;}
.box-content-center {height: 100%;display: flex;justify-content: center;align-items: center;}
.display-block {display:block;}
.desktop-hidden, .desktop-hidden-flex, .desktop-hidden-block, .desktop-hidden-inline, .desktop-hidden-inline-block {display:none;}
.mobile-hidden {display:inherit;}
.mobile-hidden-flex {display:flex;}
.mobile-hidden-block {display:block;}
.mobile-hidden-inline {display:inline;}
.mobile-hidden-inline-block {display:inline-block;}
@media (max-width: 768px) {
  .desktop-hidden {display:inherit;}
  .mobile-hidden, .mobile-hidden-flex, .mobile-hidden-block, .mobile-hidden-inline, .mobile-hidden-inline-block {display:none;}
  .desktop-hidden-flex {display:flex;}
  .desktop-hidden-block {display:block;}
  .desktop-hidden-inline {display:inline;}
  .desktop-hidden-inline-block {display:inline-block;}
}
.inline-block {display: inline-block !important;}
.sombra-1 {
  -webkit-box-shadow: 0px 10px 7px -4px rgba(0,0,0,0.14);
  -moz-box-shadow: 0px 10px 7px -4px rgba(0,0,0,0.14);
  box-shadow: 0px 10px 7px -4px rgba(0,0,0,0.14);
}
.disabled,
.disable-pointer-events {
  pointer-events: none;
}
.sombra-texto {
  text-shadow: 0 0 10px black;
}
.sombra-texto * {
  text-shadow: 0 0 10px black;
}

.height-100 {height: 100% !important;}
.width-100 {width: 100% !important;}
.width-auto {width: auto !important;}
.transition-all {transition: all 0.5s ease;}
.col-centered {float: none;margin: 0 auto;}
.overflow {overflow: auto;}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  display: inline-block;
}
.text-uppercase {text-transform: uppercase;}

.m-t-auto { margin-top: auto !important;}
.m-b-auto { margin-bottom: auto !important;}
.m-r-auto { margin-right: auto !important;}
.m-l-auto { margin-left: auto !important;}
.m-l--125 {
  margin-left: -125px; }

.m-t--125 {
  margin-top: -125px; }

.m-r--125 {
  margin-right: -125px; }

.m-b--125 {
  margin-bottom: -125px; }

.m-l--120 {
  margin-left: -120px; }

.m-t--120 {
  margin-top: -120px; }

.m-r--120 {
  margin-right: -120px; }

.m-b--120 {
  margin-bottom: -120px; }

.m-l--115 {
  margin-left: -115px; }

.m-t--115 {
  margin-top: -115px; }

.m-r--115 {
  margin-right: -115px; }

.m-b--115 {
  margin-bottom: -115px; }

.m-l--110 {
  margin-left: -110px; }

.m-t--110 {
  margin-top: -110px; }

.m-r--110 {
  margin-right: -110px; }

.m-b--110 {
  margin-bottom: -110px; }

.m-l--105 {
  margin-left: -105px; }

.m-t--105 {
  margin-top: -105px; }

.m-r--105 {
  margin-right: -105px; }

.m-b--105 {
  margin-bottom: -105px; }

.m-l--100 {
  margin-left: -100px; }

.m-t--100 {
  margin-top: -100px; }

.m-r--100 {
  margin-right: -100px; }

.m-b--100 {
  margin-bottom: -100px; }

.m-l--95 {
  margin-left: -95px; }

.m-t--95 {
  margin-top: -95px; }

.m-r--95 {
  margin-right: -95px; }

.m-b--95 {
  margin-bottom: -95px; }

.m-l--90 {
  margin-left: -90px; }

.m-t--90 {
  margin-top: -90px; }

.m-r--90 {
  margin-right: -90px; }

.m-b--90 {
  margin-bottom: -90px; }

.m-l--85 {
  margin-left: -85px; }

.m-t--85 {
  margin-top: -85px; }

.m-r--85 {
  margin-right: -85px; }

.m-b--85 {
  margin-bottom: -85px; }

.m-l--80 {
  margin-left: -80px; }

.m-t--80 {
  margin-top: -80px; }

.m-r--80 {
  margin-right: -80px; }

.m-b--80 {
  margin-bottom: -80px; }

.m-l--75 {
  margin-left: -75px; }

.m-t--75 {
  margin-top: -75px; }

.m-r--75 {
  margin-right: -75px; }

.m-b--75 {
  margin-bottom: -75px; }

.m-l--70 {
  margin-left: -70px; }

.m-t--70 {
  margin-top: -70px; }

.m-r--70 {
  margin-right: -70px; }

.m-b--70 {
  margin-bottom: -70px; }

.m-l--65 {
  margin-left: -65px; }

.m-t--65 {
  margin-top: -65px; }

.m-r--65 {
  margin-right: -65px; }

.m-b--65 {
  margin-bottom: -65px; }

.m-l--60 {
  margin-left: -60px; }

.m-t--60 {
  margin-top: -60px; }

.m-r--60 {
  margin-right: -60px; }

.m-b--60 {
  margin-bottom: -60px; }

.m-l--55 {
  margin-left: -55px; }

.m-t--55 {
  margin-top: -55px; }

.m-r--55 {
  margin-right: -55px; }

.m-b--55 {
  margin-bottom: -55px; }

.m-l--50 {
  margin-left: -50px; }

.m-t--50 {
  margin-top: -50px; }

.m-r--50 {
  margin-right: -50px; }

.m-b--50 {
  margin-bottom: -50px; }

.m-l--45 {
  margin-left: -45px; }

.m-t--45 {
  margin-top: -45px; }

.m-r--45 {
  margin-right: -45px; }

.m-b--45 {
  margin-bottom: -45px; }

.m-l--40 {
  margin-left: -40px; }

.m-t--40 {
  margin-top: -40px; }

.m-r--40 {
  margin-right: -40px; }

.m-b--40 {
  margin-bottom: -40px; }

.m-l--35 {
  margin-left: -35px; }

.m-t--35 {
  margin-top: -35px; }

.m-r--35 {
  margin-right: -35px; }

.m-b--35 {
  margin-bottom: -35px; }

.m-l--30 {
  margin-left: -30px; }

.m-t--30 {
  margin-top: -30px; }

.m-r--30 {
  margin-right: -30px; }

.m-b--30 {
  margin-bottom: -30px; }

.m-l--25 {
  margin-left: -25px; }

.m-t--25 {
  margin-top: -25px; }

.m-r--25 {
  margin-right: -25px; }

.m-b--25 {
  margin-bottom: -25px; }

.m-l--20 {
  margin-left: -20px; }

.m-t--20 {
  margin-top: -20px; }

.m-r--20 {
  margin-right: -20px; }

.m-b--20 {
  margin-bottom: -20px; }

.m-l--15 {
  margin-left: -15px; }

.m-t--15 {
  margin-top: -15px; }

.m-r--15 {
  margin-right: -15px; }

.m-b--15 {
  margin-bottom: -15px; }

.m-l--10 {
  margin-left: -10px; }

.m-t--10 {
  margin-top: -10px; }

.m-r--10 {
  margin-right: -10px; }

.m-b--10 {
  margin-bottom: -10px; }

.m-l--5 {
  margin-left: -5px; }

.m-t--5 {
  margin-top: -5px; }

.m-r--5 {
  margin-right: -5px; }

.m-b--5 {
  margin-bottom: -5px; }

.m-l-0 {
  margin-left: 0px; }

.m-t-0 {
  margin-top: 0px; }

.m-r-0 {
  margin-right: 0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-l-5 {
  margin-left: 5px; }

.m-t-5 {
  margin-top: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-10 {
  margin-left: 10px; }

.m-t-10 {
  margin-top: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-15 {
  margin-left: 15px; }

.m-t-15 {
  margin-top: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-20 {
  margin-left: 20px; }

.m-t-20 {
  margin-top: 20px; }

.m-r-20 {
  margin-right: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-25 {
  margin-left: 25px; }

.m-t-25 {
  margin-top: 25px; }

.m-r-25 {
  margin-right: 25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-l-30 {
  margin-left: 30px; }

.m-t-30 {
  margin-top: 30px; }

.m-r-30 {
  margin-right: 30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-l-35 {
  margin-left: 35px; }

.m-t-35 {
  margin-top: 35px; }

.m-r-35 {
  margin-right: 35px; }

.m-b-35 {
  margin-bottom: 35px; }

.m-l-40 {
  margin-left: 40px; }

.m-t-40 {
  margin-top: 40px; }

.m-r-40 {
  margin-right: 40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-l-45 {
  margin-left: 45px; }

.m-t-45 {
  margin-top: 45px; }

.m-r-45 {
  margin-right: 45px; }

.m-b-45 {
  margin-bottom: 45px; }

.m-l-50 {
  margin-left: 50px; }

.m-t-50 {
  margin-top: 50px; }

.m-r-50 {
  margin-right: 50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-l-55 {
  margin-left: 55px; }

.m-t-55 {
  margin-top: 55px; }

.m-r-55 {
  margin-right: 55px; }

.m-b-55 {
  margin-bottom: 55px; }

.m-l-60 {
  margin-left: 60px; }

.m-t-60 {
  margin-top: 60px; }

.m-r-60 {
  margin-right: 60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-l-65 {
  margin-left: 65px; }

.m-t-65 {
  margin-top: 65px; }

.m-r-65 {
  margin-right: 65px; }

.m-b-65 {
  margin-bottom: 65px; }

.m-l-70 {
  margin-left: 70px; }

.m-t-70 {
  margin-top: 70px; }

.m-r-70 {
  margin-right: 70px; }

.m-b-70 {
  margin-bottom: 70px; }

.m-l-75 {
  margin-left: 75px; }

.m-t-75 {
  margin-top: 75px; }

.m-r-75 {
  margin-right: 75px; }

.m-b-75 {
  margin-bottom: 75px; }

.m-l-80 {
  margin-left: 80px; }

.m-t-80 {
  margin-top: 80px; }

.m-r-80 {
  margin-right: 80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-l-85 {
  margin-left: 85px; }

.m-t-85 {
  margin-top: 85px; }

.m-r-85 {
  margin-right: 85px; }

.m-b-85 {
  margin-bottom: 85px; }

.m-l-90 {
  margin-left: 90px; }

.m-t-90 {
  margin-top: 90px; }

.m-r-90 {
  margin-right: 90px; }

.m-b-90 {
  margin-bottom: 90px; }

.m-l-95 {
  margin-left: 95px; }

.m-t-95 {
  margin-top: 95px; }

.m-r-95 {
  margin-right: 95px; }

.m-b-95 {
  margin-bottom: 95px; }

.m-l-100 {
  margin-left: 100px; }

.m-t-100 {
  margin-top: 100px; }

.m-r-100 {
  margin-right: 100px; }

.m-b-100 {
  margin-bottom: 100px; }

.m-l-105 {
  margin-left: 105px; }

.m-t-105 {
  margin-top: 105px; }

.m-r-105 {
  margin-right: 105px; }

.m-b-105 {
  margin-bottom: 105px; }

.m-l-110 {
  margin-left: 110px; }

.m-t-110 {
  margin-top: 110px; }

.m-r-110 {
  margin-right: 110px; }

.m-b-110 {
  margin-bottom: 110px; }

.m-l-115 {
  margin-left: 115px; }

.m-t-115 {
  margin-top: 115px; }

.m-r-115 {
  margin-right: 115px; }

.m-b-115 {
  margin-bottom: 115px; }

.m-l-120 {
  margin-left: 120px; }

.m-t-120 {
  margin-top: 120px; }

.m-r-120 {
  margin-right: 120px; }

.m-b-120 {
  margin-bottom: 120px; }

.m-l-125 {
  margin-left: 125px; }

.m-t-125 {
  margin-top: 125px; }

.m-r-125 {
  margin-right: 125px; }

.m-b-125 {
  margin-bottom: 125px; }

.margin-0 {
  margin: 0; }

.p-l-0 {
  padding-left: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-r-0 {
  padding-right: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.p-l-5 {
  padding-left: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-10 {
  padding-left: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-15 {
  padding-left: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-20 {
  padding-left: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-25 {
  padding-left: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-r-25 {
  padding-right: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.p-l-30 {
  padding-left: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.p-l-35 {
  padding-left: 35px; }

.p-t-35 {
  padding-top: 35px; }

.p-r-35 {
  padding-right: 35px; }

.p-b-35 {
  padding-bottom: 35px; }

.p-l-40 {
  padding-left: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.p-l-45 {
  padding-left: 45px; }

.p-t-45 {
  padding-top: 45px; }

.p-r-45 {
  padding-right: 45px; }

.p-b-45 {
  padding-bottom: 45px; }

.p-l-50 {
  padding-left: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.p-l-55 {
  padding-left: 55px; }

.p-t-55 {
  padding-top: 55px; }

.p-r-55 {
  padding-right: 55px; }

.p-b-55 {
  padding-bottom: 55px; }

.p-l-60 {
  padding-left: 60px; }

.p-t-60 {
  padding-top: 60px; }

.p-r-60 {
  padding-right: 60px; }

.p-b-60 {
  padding-bottom: 60px; }

.p-l-65 {
  padding-left: 65px; }

.p-t-65 {
  padding-top: 65px; }

.p-r-65 {
  padding-right: 65px; }

.p-b-65 {
  padding-bottom: 65px; }

.p-l-70 {
  padding-left: 70px; }

.p-t-70 {
  padding-top: 70px; }

.p-r-70 {
  padding-right: 70px; }

.p-b-70 {
  padding-bottom: 70px; }

.p-l-75 {
  padding-left: 75px; }

.p-t-75 {
  padding-top: 75px; }

.p-r-75 {
  padding-right: 75px; }

.p-b-75 {
  padding-bottom: 75px; }

.p-l-80 {
  padding-left: 80px; }

.p-t-80 {
  padding-top: 80px; }

.p-r-80 {
  padding-right: 80px; }

.p-b-80 {
  padding-bottom: 80px; }

.p-l-85 {
  padding-left: 85px; }

.p-t-85 {
  padding-top: 85px; }

.p-r-85 {
  padding-right: 85px; }

.p-b-85 {
  padding-bottom: 85px; }

.p-l-90 {
  padding-left: 90px; }

.p-t-90 {
  padding-top: 90px; }

.p-r-90 {
  padding-right: 90px; }

.p-b-90 {
  padding-bottom: 90px; }

.p-l-95 {
  padding-left: 95px; }

.p-t-95 {
  padding-top: 95px; }

.p-r-95 {
  padding-right: 95px; }

.p-b-95 {
  padding-bottom: 95px; }

.p-l-100 {
  padding-left: 100px; }

.p-t-100 {
  padding-top: 100px; }

.p-r-100 {
  padding-right: 100px; }

.p-b-100 {
  padding-bottom: 100px; }

.p-l-105 {
  padding-left: 105px; }

.p-t-105 {
  padding-top: 105px; }

.p-r-105 {
  padding-right: 105px; }

.p-b-105 {
  padding-bottom: 105px; }

.p-l-110 {
  padding-left: 110px; }

.p-t-110 {
  padding-top: 110px; }

.p-r-110 {
  padding-right: 110px; }

.p-b-110 {
  padding-bottom: 110px; }

.p-l-115 {
  padding-left: 115px; }

.p-t-115 {
  padding-top: 115px; }

.p-r-115 {
  padding-right: 115px; }

.p-b-115 {
  padding-bottom: 115px; }

.p-l-120 {
  padding-left: 120px; }

.p-t-120 {
  padding-top: 120px; }

.p-r-120 {
  padding-right: 120px; }

.p-b-120 {
  padding-bottom: 120px; }

.p-l-125 {
  padding-left: 125px; }

.p-t-125 {
  padding-top: 125px; }

.p-r-125 {
  padding-right: 125px; }

.p-b-125 {
  padding-bottom: 125px; }

.padding-0 {
  padding: 0; }

.font-6 {
  font-size: 6px; }

.font-7 {
  font-size: 7px; }

.font-8 {
  font-size: 8px; }

.font-9 {
  font-size: 9px; }

.font-10 {
  font-size: 10px; }

.font-11 {
  font-size: 11px; }

.font-12 {
  font-size: 12px; }

.font-13 {
  font-size: 13px; }

.font-14 {
  font-size: 14px; }

.font-15 {
  font-size: 15px; }

.font-16 {
  font-size: 16px; }

.font-17 {
  font-size: 17px; }

.font-18 {
  font-size: 18px; }

.font-19 {
  font-size: 19px; }

.font-20 {
  font-size: 20px; }

.font-21 {
  font-size: 21px; }

.font-22 {
  font-size: 22px; }

.font-23 {
  font-size: 23px; }

.font-24 {
  font-size: 24px; }

.font-25 {
  font-size: 25px; }

.font-26 {
  font-size: 26px; }

.font-27 {
  font-size: 27px; }

.font-28 {
  font-size: 28px; }

.font-29 {
  font-size: 29px; }

.font-30 {
  font-size: 30px; }

.font-31 {
  font-size: 31px; }

.font-32 {
  font-size: 32px; }

.font-33 {
  font-size: 33px; }

.font-34 {
  font-size: 34px; }

.font-35 {
  font-size: 35px; }

.font-36 {
  font-size: 36px; }

.font-37 {
  font-size: 37px; }

.font-38 {
  font-size: 38px; }

.font-39 {
  font-size: 39px; }

.font-40 {
  font-size: 40px; }

.font-41 {
  font-size: 41px; }

.font-42 {
  font-size: 42px; }

.font-43 {
  font-size: 43px; }

.font-44 {
  font-size: 44px; }

.font-45 {
  font-size: 45px; }

.font-46 {
  font-size: 46px; }

.font-47 {
  font-size: 47px; }

.font-48 {
  font-size: 48px; }

.font-49 {
  font-size: 49px; }

.font-50 {
  font-size: 50px; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.align-justify {
  text-align: justify; }

.no-resize {
  resize: none; }

.font-normal {
    font-weight: normal; }

.font-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.font-underline {
  text-decoration: underline; }

.font-line-through {
  text-decoration: line-through; }

.font-overline {
  text-decoration: overline; }

  
//
// Rotating border
//

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  border: .25em solid;
  border-color: currentColor transparent currentColor currentColor;
  border-radius: 50%;
  animation-name: spinner-border;
  animation-duration: .75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-border-reverse {
  border-color: transparent currentColor transparent transparent;

  &::after {
    position: absolute;
    top: -.25em;
    right: -.25em;
    bottom: -.25em;
    left: -.25em;
    display: inline-block;
    content: "";
    border: .25em solid rgba(0, 0, 0, .1);
    border-radius: 50%;
  }
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;

  &.spinner-border-reverse::after {
    border-width: .2em;
  }
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.spinner-grow {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  animation-name: spinner-grow;
  animation-duration: .75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}