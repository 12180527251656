@import url('~assets/appui/css/bootstrap.min.css');
@import url('~assets/appui/css/plugins.css');
@import url('~assets/appui/css/main.css');
@import url('~assets/appui/css/themes/peces.css');
@import url('~assets/appui/css/themes.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import url('~helpers.scss');
:root {
    --font-primary: 'Poppins';
    --font-secondary: 'Poppins';
    --font-size: 14px;
    --color-primary: #d8125a;
    --color-primary-hover: #f72d77;
    --color-secondary: #061B25;
    --color-secondary-hover: #0b2733;
    --color-form-control:  #0b2733;
    --header-glass: #092c3b;
    --btn-default: #F0F0F0;
    --btn-default-hover: #DDD;
    --btn-primary: #d8125a;
    --btn-primary-hover: #f72d77;
    --btn-secondary: #374249;
    --btn-secondary-hover: #48565e;
    --btn-login: #061B25;
    --btn-login-hover: #0b2733;
    --logo-height: 160px;
    --logo-height-movil: 150px;
    --logo-menu-width: 60%;
    --bg-body: #ebeff2;
    --bg-login: #061B25;
    --bg-01: #F2BB2F;
    --bg-02: #003A70;
    --bg-03: #5AB0E3;
    --bg-04: #E73351;
    --bg-05: #F2BB2F;
    --display-triangulo-left-header: block;
    --display-triangulo-right-header: block;
}

body {
    background-color: var(--bg-body);
    font-family: var(--font-primary);
    font-size: var(--font-size);
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-primary);
}

.bg-body-login {
    background-color: var(--bg-login);
}
.bg-body-login .full-bg {
    display: none;
}

.footer-login {
    text-align: center;
    line-height: 1.1;
    white-space: nowrap;
    font-size: 14px;
}
.footer-login a {
    display: inline-block;
    width: 175px;
    text-align: left;
    color: #FFF;
    text-decoration: none;
}
.footer-login img {
    height: 30px;
    margin-right: 15px;
    float: left;
}
.footer-login span {
    display: block;
    color: #FFF;
    opacity: .7;
}

#page-content {
    background-color: #ebeef2;
}
#page-content.dashboard {
    padding: 10px 20px;
}

.font-primary {font-family: var(--font-primary) !important;}
.font-secondary {font-family: var(--font-secondary) !important;}

.text-primary {color: var(--color-primary) !important;}
.text-white {color: #FFF;}
.text-black {color: #000;}

.bg-primary {background-color: var(--color-primary)}
.bg-secondary {background-color: var(--color-secondary)}
.bg-header {background-color: #061B25;}

.navbar.navbar-inverse.navbar-glass {
    background: var(--header-glass) ;
}
.sidebar-nav li ul li a {
    line-height: 1.3 !important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.btn-default {
    background-color: var(--btn-default);
    border-color: var(--btn-default);
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.active,
.btn-default.active:hover,
.btn-default.active:focus,
.open .btn-default.dropdown-toggle,
.open .btn-default.dropdown-toggle:hover,
.open .btn-default.dropdown-toggle:focus,
.open .btn-default.dropdown-toggle.focus {
    background-color: var(--btn-default-hover);
    border-color: var(--btn-default-hover);
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
.btn-default[disabled]:active:focus,
.btn-default[disabled].active:focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active:focus,
fieldset[disabled] .btn-default.active:focus {
    background-color: var(--btn-default-hover);
    border-color: var(--btn-default-hover);
}

.btn-primary,
.fc-state-default {
    background-color: var(--btn-primary);
    border-color: var(--btn-primary);
}
.btn-default {
    background-color: var(--btn-default);
    border-color: var(--btn-default);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary.active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.open .btn-primary.dropdown-toggle,
.open .btn-primary.dropdown-toggle:hover,
.open .btn-primary.dropdown-toggle:focus,
.open .btn-primary.dropdown-toggle.focus {
    background-color: var(--btn-primary-hover);
    border-color: var(--btn-primary-hover);
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
.btn-primary[disabled]:active:focus,
.btn-primary[disabled].active:focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary:active:focus,
fieldset[disabled] .btn-primary.active:focus {
    background-color: var(--btn-primary-hover);
    border-color: var(--btn-primary-hover);
}

.btn-secondary {
    background-color: var(--btn-secondary);
    border-color: var(--btn-secondary);
    color: #FFF;
}
.btn-secondary:hover {
    color: #FFF;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:active,
.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary.active,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.open .btn-secondary.dropdown-toggle,
.open .btn-secondary.dropdown-toggle:hover,
.open .btn-secondary.dropdown-toggle:focus,
.open .btn-secondary.dropdown-toggle.focus {
    background-color: var(--btn-secondary-hover);
    border-color: var(--btn-secondary-hover);
}

.btn-secondary.disabled,
.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled]:hover,
.btn-secondary[disabled]:focus,
.btn-secondary[disabled].focus,
.btn-secondary[disabled]:active,
.btn-secondary[disabled].active,
.btn-secondary[disabled]:active:focus,
.btn-secondary[disabled].active:focus,
fieldset[disabled] .btn-secondary:hover,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary.focus,
fieldset[disabled] .btn-secondary:active,
fieldset[disabled] .btn-secondary.active,
fieldset[disabled] .btn-secondary:active:focus,
fieldset[disabled] .btn-secondary.active:focus {
    background-color: var(--btn-secondary-hover);
    border-color: var(--btn-secondary-hover);
}

.btn-danger {
    background-color: #E40000;
    border-color: #E40000;
}

.btn-cliente-activo {
    margin-right: 50px;
    background-color: var(--color-primary);
}
.btn-cliente-activo a {
    display: block;
    padding-left: 15px !important;
    padding-right: 30px !important;
}
.btn-cliente-activo .btn-cerrar {
    position: absolute;
    top: 0; bottom: 0; right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
}
.btn-cliente-activo .btn-cerrar i {
    color: #FFF;
    font-size: 20px;
}
@media (max-width:768px) {
    .btn-cliente-activo {
        margin-right: 30px;
    }
    .btn-cliente-activo a {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

#login-container .block {
    background: #d8125a;
    color: #fff;
    border: 3px solid #fff;
    box-shadow: 0 3px 18px rgb(0 0 0 / 67%);
    padding: 30px 45px;
}
#login-container .block-title {
    background-color: transparent;
    border: none;
    margin: 0;
}
#login-container .block-title h2 {
    padding: 0;
    padding-bottom: 20px;
}
#login-container .logo {
    display: inline-block;
    height: 80px;
}
#login-container .csscheckbox, #login-container p {
    line-height: 1;
}
#login-container .btn-primary {
    background-color: var(--btn-login);
    border-color: var(--btn-login);
}
#login-container input {
    border-color: #061B25;
}
#login-container input::placeholder {
    color: #061B25;
    opacity: 1;
}
#login-container input:-ms-input-placeholder {
    color: #061B25;
}
#login-container input::-ms-input-placeholder {
    color: #061B25;
}
#login-container .input-group {
    display: flex;
}
#login-container .input-group-addon:first-child {
    border-color: #061B25;
}
#login-container .input-group-addon {
    color: #061B25;
    font-size: 16px;
    height: 34px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    min-width: auto;
}
@media screen and (min-width: 768px) {
    #login-container {
        width: 385px;
        top: 120px;
        margin-left: -192px;
    }
}
.sidebar-title img {
    height: 30px;
}
#sidebar-toggle-full {
    font-size: 26px;
    margin-top: 5px;
}

.sidebar-brand-cliente .sidebar-title, .sidebar-brand-cliente {
    background-color: transparent !important;
    text-align: center;
}
.sidebar-brand-cliente .sidebar-title {
    display: block;
    height: auto !important;
}
.sidebar-brand-cliente .sidebar-title img {
    height: 120px;
    width: 75% !important;
    object-fit: contain;
    margin: auto;
}
@media (min-width: 992px) {
    .sidebar-brand-cliente {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        padding-top: 10px  !important;
        padding-bottom: 0  !important;
        height: auto !important;
    }
}

.sub-tarifa {
    padding: 0 10px;
    // padding-left: 38px;
    padding-bottom: 15px;
    margin-top: -10px;
    font-size: 12px;
    font-style: italic;
    opacity: 0.5;
}

.table thead > tr > th,
.table tbody > tr > td {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.content-header h1 {
    display: inline-block;
}
.breadcrumb-top.text-left {
    text-align: left !important;
    padding-left: 0;
}
.my-auto {
    display: flex;
    align-items: center;
    min-height: 100px;
    flex-wrap: wrap;
}
.my-auto .header-section {
    flex-basis: 100%;
}

.table-grid.block {
    padding: 0;
}
@media (max-width: 768px) {
    .my-auto {
        min-height: auto;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .table-grid.break-padding {
        margin-left: inherit;
        margin-right: inherit;
    }
    .table-grid.block {
        border: none;
        background: transparent;
        box-shadow: none;
    }
    .table-grid thead {
        display: none;
    }
    .table-grid tbody {
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
    }
    .table-grid tbody tr {
        padding: 10px 15px;
        margin-bottom: 10px;
        border-radius: 8px;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-wrap: wrap;
    }
    .table-grid tbody tr td {
        border: none;
    }
    .table-grid tbody tr td.breaktd {
        flex-basis: 100%;
    }
    .table-grid tbody tr td.btntd {
        margin-top: 10px;
        margin-left: auto;
        font-size: 18px;
    }
    .table-grid tbody tr td.btntd ~ td.btntd {
        margin-top: 10px;
        margin-left: 0;
    }
    .table-grid tbody tr td.btntd .btn {
        padding: 0.3rem 0.7rem;
        font-size: 18px;
        line-height: 1.5;
    }
}

.input-group-append {
    display: table-cell;
    padding-top: 0;
    vertical-align: top;
}
.input-group-addon {
    font-size: 19px;
    width: auto;
}
.input-group-addon:first-child {
    border-right: 1px solid #dae0e8;
}

.break-padding {
    margin-left: -20px;
    margin-right: -20px;
}
@media (max-width: 768px) {
    .break-padding {
        margin-left: unset;
        margin-right: unset;
    }
}

.header-section {
    padding-bottom: 0;
}

.img-preview-container {
    display: inline-block;
    min-height: 130px;
    text-align: center;
    padding: 10px;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
}
.img-preview-container img {
    max-width: 100%;
    max-height: 200px;
    border-radius: 3px;
}
.row-equal-cols {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-wrap: wrap;
}
.row-equal-cols > [class*='col-'] {
    display: flex;
    flex-direction: column;
}
.col-botones {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row !important;
    padding-bottom: 10px;
}
@media (max-width: 768px) {
    .col-botones {
        margin-top: 20px;
        justify-content: center;
    }
}

.img-circle {
    border-radius: 50%;
}
.img-circle.square {
    width: 40px;
    height: 40px;
}
.img-circle.menu {
    position: relative;
    width: 70%;
    height: auto;
    padding-top: 70%;
}
.img-usuario {
    object-fit: contain;
}
.img-listado {
    width: 36px;
    height: 36px;
    object-fit: cover;
}
.img-listado.lg {
    width: 50px;
    height: 50px;
}

.tox-notifications-container {
    display: none !important;
}

.chosen-iconos .chosen-single,
.chosen-iconos .chosen-drop {
    font-size: 16px;
}
.chosen-iconos .chosen-single span,
.chosen-iconos .chosen-drop span {
    height: 40px;
}
.chosen-iconos .chosen-single i,
.chosen-iconos .chosen-drop i {
    opacity: .6;
    font-size: 30px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.chosen-iconos .chosen-container-single .chosen-single div {
    position: static;
    display: inline-block;
}

.calendario-open {
    padding: 10px;
    border: 1px solid #E4E4E4;
    width: 47%;
    display: inline-block;
}
.calendario-open th,
.calendario-open td {
    font-size: 14px;
    padding: 10px !important;
}
.calendario-open .datepicker-inline {
    width: unset !important;
}
.datepicker table tr td.disabled {
    background: #EEE;
    color: #454545;
}
.datepicker table tr td.bloqueada,
.legenda span.bloqueada {
    background: #000;
    color: #FFF;
}
.datepicker table tr td.bloqueada.reservada,
.legenda span.bloqueada.reservada {
    background: #D77248;
    color: #FFF;
    pointer-events: none;
}
.datepicker table tr td.bloqueada.booking ,
.legenda span.bloqueada.booking {
    background: #003990;
    color: #FFF;
    pointer-events: none;
}
.datepicker table tr td.bloqueada.airbnb,
.legenda span.bloqueada.airbnb {
    background: #F73659;
    color: #FFF;
    pointer-events: none;
}
.datepicker table tr td.bloqueada.booking-airbnb {
    position: relative;
    background: transparent;
    color: #FFF;
    pointer-events: none;
    z-index: 1;
}
.datepicker table tr td.bloqueada.booking-airbnb::before {
    content: '';
    display: block;
    background: #003990;
    position: absolute;
    top: 0; bottom: 0; left: 0; width: 50%;
    z-index: -1;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.datepicker table tr td.bloqueada.booking-airbnb::after {
    content: '';
    display: block;
    background: #F73659;
    position: absolute;
    top: 0; bottom: 0; right: 0; width: 50%;
    z-index: -1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.datepicker table tr td:hover {
    background: #000 !important;
    color: #FFF !important;
}
.legenda div {
    padding: 5px 10px;
    font-size: 12px;
    width: fit-content;
    float: left;
}
.legenda span {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 15px;
    vertical-align: middle;
    border-radius: 4px;
}

.resumen-total {
    font-size: 18px;
    padding: 5px 15px;
}
.resumen-total p {
    margin: 0;
}
.resumen-total span {
    font-weight: bold;
    color: var(--color-primary);
}
.resumen-total .total {
    font-weight: bold;
    font-size: 30px;
}
.daterangepicker td.disabled, .daterangepicker option.disabled {
    color: #D77248 !important;
}
table div[contenteditable] {
    background-color: #ececec;
    border-radius: 5px;
    padding: 5px 10px;
}

.title-border-bottom {
    padding-bottom: 5px;
    border-bottom: 1px solid #787878;
}

.loadingoverlay {
    justify-content: center !important;
}
.loadingoverlay_text {
    font-size: 25px !important;
    margin-top: 20px !important;
    color: var(--color-primary) !important;
}

.bg-tr-cancelada,
.bg-tr-cancelada span,
.bg-tr-cancelada a:not(.btn),
.bg-tr-cancelada .text-primary {
    color: #9b9b9b !important;
}
.active.day{
    background-color: unset !important;
    color: unset !important;
}
.active.day.bloqueada{
    background: #000 !important;
    color: #FFF !important;
}
#calendario-cierres .datepicker table{
    width: 100%;
}
#content-imputs-rangue-block{
    max-height: 370px;
    overflow-y: auto;
}

#calendario-noches-container .daterangepicker {
    display: block !important;
    position: static;
    border: none;
}
#calendario-noches-container .daterangepicker::before {
    display: none;
}
#calendario-noches-container .drp-buttons {
    display: none;
}
#calendario-noches-container .daterangepicker .highlight-1 {
    background-color: #02294b;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-2 {
    background-color: #003A70;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-3 {
    background-color: #D77248;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-4 {
    background-color: #454545;
    color: #FFF;
}
#calendario-noches-container .daterangepicker .highlight-5 {
    background-color: #5AB0E3;
    color: #FFF;
}

.copyright {
    position: absolute;
    left: 0; right: 0; bottom: 0;
    background-color: var(--color-primary);
    color: #FFF;
    padding: 10px 10px;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.copy-title {
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
}
.copy-enlace {
    color: #FFF;
    font-size: 12px;
    display: block;
    line-height: 1.2;
}
.copy-enlace:hover {
    color: #FFF;
}

.loadingoverlay_element {
    width: 300px !important;
}
.licencia {
    position: absolute;
    left: 0; right: 0; bottom: 70px;
    color: #FFF;
    padding: 10px 28px;
}
.licencia h3 {
    display: block;
    color: #FFF;
    font-size: 14px;
    margin: 2px 0;
}
.licencia a {
    display: block;
    color: #FFF;
    font-size: 12px;
}
.licencia p {
    margin: 2px 0;
    font-size: 12px;
}

.ev-general {
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
}
.ev-general .titulo {
    font-size: 12px;
    margin-bottom: 5px;
    // white-space: normal;
    line-height: 1;
}
.ev-general img {
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    margin-bottom: 5px;
    object-fit: cover;
}
.ev-general .cliente {
    font-size: 10px;
    color: #FFF;
    font-weight: bold;
    white-space: normal;
}
.ev-general .fecha {
    font-size: 10px;
    color: #FFF;
    white-space: normal;
}
.fc .fc-scroller-liquid-absolute {
    overflow: auto !important;
}
.calendar-container .datepicker table tr td:hover {
    background: inherit !important;
    color: inherit !important;
}

.table-listado-eventos {
    max-height: 73vh;
    overflow: auto;
}
@media (max-width: 1366px) {
    .table-listado-eventos {
        max-height: 68vh;
    }
}
@media (max-width: 1200px) {
    .table-listado-eventos {
        max-height: 375px;
    }
}

.popover {
    min-width: 260px;
}
.popover-title {
    color: var(--color-primary);
    font-size: 16px;
}
.popover-btn {
    list-style: none;
    padding: 0;
}
.popover-btn .btn {
    display: block;
    margin: 10px 0;
}
.popover-body img {
    width: 100%;
    height: auto;
    max-height: 230px;
    object-fit: cover;
    display: block;
    margin-bottom: 5px;
}
.popover-body .categoria {
    font-weight: bold;
    font-size: 16px;
}
.popover-body .organizador {
    font-size: 14px;
}
.popover-body .localidad {
    font-size: 14px;
}
.popover-body .fecha {
    font-size: 14px;
    margin-bottom: 5px;
}
.popover-body .organizador i,
.popover-body .localidad i,
.popover-body .fecha i {
    width: 16px;
}
.popover-body .descripcion {
    font-size: 14px;
    line-height: 1.2 !important;
}

.badges {
    cursor: grab;
    white-space: nowrap;
    overflow-x: auto;
    text-align: right;
    width: 80%;
    float: right;
    list-style: none;
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    vertical-align: middle;
    margin-top: 8px;
}
.badges .badge {
    // pointer-events: none;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.badges::-webkit-scrollbar {
    display: none;
}
@media (max-width:768px) {
    .badges {
        width: 100%;
        margin-bottom: 20px;
        padding: 0 15px;
    }
}

#modal-nueva-reserva .modal-dialog {
    width: 700px;
    margin-top: 20px;
}
#modal-nueva-reserva .modal-body {
    height: 72vh !important;
}
@media (max-width:768px) {
    #modal-nueva-reserva .modal-dialog {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        width: unset;
    }
    #modal-nueva-reserva .modal-content {
        height: 100vh !important;
    }
    #modal-nueva-reserva .modal-body {
        height: 84% !important;
    }
    #modal-nueva-reserva .modal-footer {
        position: absolute;
        bottom: 0; left: 0; right: 0;
    }
}

.no-resultados {
    text-align: center;
    font-size: 18px;
    padding: 20px;
    color: #656565;
}
.no-resultados div {
    margin-bottom: 15px;
}

.widget-sugerencia {
    position: relative;
}
.widget-sugerencia {
    position: relative;
}
.widget-sugerencia img {
    border-radius: 4px;
}
.widget-sugerencia .btn-danger {
    position: absolute;
    top: 0; right: 0;
}

#calendario {
    display: flex;
    justify-content: center;
}
.litepicker {
    font-family: var(--font-primary);
    font-size: 1.2em;
}
.container__months {
    box-shadow: none !important;
}
.month-item-name {
    text-transform: capitalize;
}
.litepicker .container__days .day-item.is-highlighted {
    background-color: #FFF;
    position: relative;
}
.litepicker .container__days .day-item.is-highlighted::after {
    content: '';
    display: block;
    background-color: var(--color-primary);
    width: 6px;
    height: 6px;
    position: absolute;
    left: calc(50% - 3px);
    top: 2px;
    border-radius: 3px;
}
.litepicker .container__days .day-item.is-locked {
    background-color: #F2F2F2;
}
.litepicker .container__days .day-item.is-today {
    color: var(--color-primary);
}
.litepicker .container__days .day-item.is-start-date {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #FFF;
}
.litepicker .container__days .day-item.is-start-date::after {
    background-color: #FFF;
}
.litepicker .container__days .day-item.is-in-range {
    background-color: var(--color-primary);
    color: #FFF;
    opacity: .8;
}
.litepicker .container__days .day-item.is-end-date {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #FFF;
}
.litepicker .container__days .day-item:hover {
    color: var(--color-primary);
    -webkit-box-shadow: inset 0 0 0 1px var(--color-primary);
    box-shadow: inset 0 0 0 1px var(--color-primary);
}

.noselect, .noselect * {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.selector-numero {
    padding: 0 15px;
    font-size: 20px;
    margin-top: -5px;
}
.selector-numero.left {
    float: left;
}
.selector-numero.right {
    float: right;
}
.selector-numero .btn-menos,
.selector-numero .btn-mas {
    font-size: 32px;
    opacity: .7;
    color: var(--color-primary);
    cursor: pointer;
}
.selector-numero .btn-menos[disabled="true"],
.selector-numero .btn-mas[disabled="true"] {
    opacity: .4;
}
.selector-numero span {
    margin: 0 15px;
}


:root {
    --color-comida: #F2BB2F;
    --color-cena: #011d36;
}
.reserva-restaurante::before {
    content: '';
    display: block;
    height: 5px;
    background-color: var(--color-comida);
}
.reserva-restaurante.cena::before {
    background-color: var(--color-cena);
}
.btn-comida {
    background-color: var(--color-comida);
    color: #FFF;
}
.btn-cena {
    background-color: var(--color-cena);
    color: #FFF;
}
.reserva-restaurante .autor {
    // position: absolute;
    // bottom: 10px; left: 16px;
    color: #707070;
    font-size: 10px;
}
@media (max-width: 768px) {
    .reserva-restaurante {
        font-size: 0.9em;
    }
    .reserva-restaurante .h4 {
        font-size: 15px;
    }
}

/*FRONTEND*/
.response-reserva {
    padding: 20px 30px;
    position: relative;
    text-align: center;
}
.response-reserva h1 {
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
}
.datos-reserva {
    text-align: left;
    max-width: 600px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 40px !important;
}
.datos-reserva .datos {
    font-size: 13px;
}
.datos-reserva .dato-destacado {
    font-size: 15px;
    font-weight: bold;
}
.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
@media (max-width: 500px) {
    .row-small .col-xs-6 {
        width: 100%;
    }
}

.btn-turnos {
    display: flex;
}
@media (max-width: 500px) {
    .btn-turnos {
        flex-direction: column;
        width: 100%;
    }
    .btn-turnos .btn {
        width: 100%;
    }
}

.badges-espacios {
    margin-top: 10px;
}
.badges-espacios .badge {
    background-color: var(--color-primary);
    padding: 8px 15px;
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.badges-espacios .badge.all {
    background-color: #bbbbbb;
}

.widget-scroll {
    height: 244px;
    overflow: auto;
}
.widget-con-imagen {
    position: relative;
}
.widget-con-imagen {
    position: relative;
}
.widget-con-imagen img {
    border-radius: 4px;
    height: 109px;
    object-fit: cover;
}
.widget-con-imagen .btn-danger {
    position: absolute;
    top: 0; right: 0;
}
.widget-con-imagen .descripcion {
    font-size: 12px;
    line-height: 1.1em;
    height: calc(1.1em * 4);
    overflow: hidden;
}

.btn-circle-sm {
    font-size: 18px;
    opacity: 0.7;
    background-color: var(--color-secondary);
    color: #FFF;
    cursor: pointer;
    border-radius: 20px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
}
.btn-circle-sm:hover, .btn-circle-sm:active {
    color: #FFF;
}

.sweet-alert .sa-icon {
    margin-bottom: 30px;
}

.badge-whatsapp {
    position: absolute;
    top: 10px; right: 10px;
    background-color: #E40000;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.badge-whatsapp-sm {
    position: absolute;
    top: 2px; right: 2px;
    background-color: #E40000;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}